import contracts from "./contracts";
import { DualFarmConfig, QuoteToken } from "./types";

const farms: DualFarmConfig[] = [
  {
    pid: -68,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x6eb10eb35e8b81a013fe98129b1c93d840bc0c62",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094F5A7D1146BB93850f629501eB749f6Ed491",
    },
    tokenSymbol: "CROX",
    tokenPrice: 0.045,
    tokenAddresses: {
      97: "",
      56: "0x2c094F5A7D1146BB93850f629501eB749f6Ed491",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 15,
    newPool: 1650287179000,
    isDualFarm: false,
    isHintVisible: false,
    active: false,
    reward2: 10000,
    projectLink: "https://spacedust.financial/",
    depositLink:
      "https://exchange.croxswap.com/",
  },
  {
    pid: -67,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x321E181D7BA5bA2DDCaB945eafe87F0C5266f6Ca",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094F5A7D1146BB93850f629501eB749f6Ed491",
    },
    tokenSymbol: "DUST",
    tokenPrice: 0.00000311459,
    tokenAddresses: {
      97: "",
      56: "0xE36E4CdF538ab62d283d1AE49007EEdFA9020497",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    newPool: 1648648382000,
    isDualFarm: false,
    isHintVisible: false,
    active: false,
    reward2: 4590000000,
    projectLink: "",
    depositLink:
      "https://exchange.croxswap.com/",
  },
  {
    pid: -66,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x092da4c8660eeb06db91143ec66579cd20585983",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094F5A7D1146BB93850f629501eB749f6Ed491",
    },
    tokenSymbol: "PUNKS",
    tokenPrice: 0.00000000913,
    tokenAddresses: {
      97: "",
      56: "0x3ca35c4f8bc33D44d10d379F3fE601bA67bBbE7f",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    newPool: 1648648382000,
    isDualFarm: false,
    isHintVisible: false,
    active: false,
    reward2: 166666666667,
    projectLink: "http://streetpunks.art/",
    depositLink:
      "https://exchange.croxswap.com/",
  },
  {
    pid: -65,
    risk: 5,
    lpSymbol: "PUNKS",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x4f916e0de4ba4d9be6b0522c5d242370c4fa38a6",
    },
    lpAddresses: {
      97: "",
      56: "0x3ca35c4f8bc33D44d10d379F3fE601bA67bBbE7f",
    },
    tokenSymbol: "PUNKS",
    tokenPrice: 0.00000000913,
    tokenAddresses: {
      97: "",
      56: "0x3ca35c4f8bc33D44d10d379F3fE601bA67bBbE7f",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    newPool: 1648648382000,
    isDualFarm: false,
    isHintVisible: false,
    active: false,
    reward2: 222222000000,
    projectLink: "http://streetpunks.art/",
    depositLink:
      "https://exchange.croxswap.com/#/swap?outputCurrency=0x3ca35c4f8bc33D44d10d379F3fE601bA67bBbE7f",
  },
  {
    pid: -64,
    risk: 5,
    lpSymbol: "PUNKS-BNB",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x6587aff08531d30ce112e8e4d931d019a02d655a"
    },
    lpAddresses: {
      97: "",
      56: "0xF1dbb9c910654cf58E1E891bd21f15d125396130"
    },
    tokenSymbol: "PUNKS",
    tokenPrice: 0.00000000913,
    quoteTokenPrice: 500,
    tokenAddresses: {
      97: "",
      56: "0x3ca35c4f8bc33D44d10d379F3fE601bA67bBbE7f"
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    minStaking: 30,
    active: false,
    isDualFarm: true,
    newPool: 1648648382000,
    reward1: 3000,
    reward2: 166666666667,
    projectLink: "http://streetpunks.art/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0x3ca35c4f8bc33D44d10d379F3fE601bA67bBbE7f",
  },
  {
    pid: -63,
    risk: 5,
    lpSymbol: "XWIN",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x3ED1f0F88b46050CC3305Cc73CEd8aC2C6529F45",
    },
    lpAddresses: {
      97: "",
      56: "0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28",
    },
    tokenSymbol: "XWIN",
    tokenPrice: 1.5,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    newPool: 1643529600000,
    isDualFarm: false,
    isHintVisible: false,
    active: false,
    reward2: 5700,
    projectLink: "https://xwin.finance/",
    depositLink:
      "https://exchange.babyswap.finance/#/swap?outputCurrency=0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28",
  },
  {
    pid: -62,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x4de4e7759e6fcfecb1b5be5949efd5deb3097721",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenDecimal: 8,
    tokenSymbol: "CNR",
    tokenPrice: 0.07,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xdCbA3fbd7BBc28abD18A472D28358089467A8a74",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    isDualFarm: false,
    newPool: 1643529600000,
    active: false,
    reward2: 70000,
    projectLink: "https://www.centric.com/",
    depositLink:
      "https://exchange.croxswap.com/#/swap?outputCurrency=0x2c094f5a7d1146bb93850f629501eb749f6ed491",
  },
  {
    pid: -61,
    risk: 5,
    lpSymbol: "CNS-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x2b350805f60e6cdda477109f5490788226b31148",
    },
    lpAddresses: {
      97: "",
      56: "0x50605A84752eA65d6FC8dB922bBd957a381920CC",
    },
    tokenDecimal: 8,
    tokenSymbol: "CNR",
    tokenPrice: 0.07,
    quoteTokenPrice: 500,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xdCbA3fbd7BBc28abD18A472D28358089467A8a74",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    minStaking: 30,
    active: false,
    isDualFarm: true,
    newPool: 1643529600000,
    reward1: 10000,
    reward2: 70000,
    projectLink: "https://www.centric.com/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0xf6cb4ad242bab681effc5de40f7c8ff921a12d63",
  },
  {
    pid: -60,
    risk: 5,
    lpSymbol: "IF1-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0xc13CA483E53caC9598F5712EA2181e0506b49443",
    },
    lpAddresses: {
      97: "",
      56: "0x4b5676c8775f035405c817E11C2ecB3CE5e5ef90",
    },
    tokenDecimal: 9,
    tokenSymbol: "IF1",
    tokenPrice: 0.000000064,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xfcac1a3ede7b55cc51e3ebff2885a67fbfe01a1a",
    },
    quoteTokenPrice: 500,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    active: false,
    newPool: 1642924800000,
    isDualFarm: true,
    reward1: 3000,
    reward2: 40000000000,
    projectLink: "https://infiniteone.com",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0xfCaC1a3eDE7b55Cc51e3eBff2885a67FBfE01a1A",
  },
  {
    pid: -59,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0xDA1550a33867001aa9Dc4a5B17F074aF8cC4689C",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenDecimal: 9,
    tokenSymbol: "IF1",
    tokenPrice: 0.000000064,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xfcac1a3ede7b55cc51e3ebff2885a67fbfe01a1a",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    newPool: 1642924800000,
    isDualFarm: false,
    active: false,
    reward2: 20000000000,
    projectLink: "https://infiniteone.io/",
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -58,
    risk: 5,
    lpSymbol: "CROX-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0x54b89cbb58e9226726d2272b40953ca031dad3f1",
    },
    lpAddresses: {
      97: "",
      56: "0xf41aCbA80328C076A207baDCbbd7bc331c0853f5",
    },
    tokenSymbol: "FCF",
    tokenPrice: 0.0001441,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x4673f018cc6d401aad0402bdbf2abcbf43dd69f3",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 30,
    active: false,
    newPool: 1642924800000,
    isDualFarm: true,
    reward1: 30000,
    reward2: 6000000,
    isBoosterPool: true,
    showBackground: true,
    projectLink: "https://croxswap.com",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x2c094F5A7D1146BB93850f629501eB749f6Ed491/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -57,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0xbe5147d1fa1c013bb11339ef4349f4b3206f5fdd",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "WSOW",
    tokenPrice: 0.03,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xe70D287AaD130e2Cee520e75d12C6efa4f1A377d",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    cmcId: 17209,
    duration: 60,
    newPool: 1642924800000,
    isDualFarm: false,
    active: false,
    reward2: 140000,
    projectLink: "https://rxseedcoin.io/",
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -56,
    risk: 5,
    lpSymbol: "WSOW-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0xc09c3ef4192e2c70a5497dc82d717f94eeba946f",
    },
    lpAddresses: {
      97: "",
      56: "0x8b9f632dd108d078154c0312294ebcd4de0845b4",
    },
    tokenSymbol: "WSOW",
    tokenPrice: 0.03,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xe70D287AaD130e2Cee520e75d12C6efa4f1A377d",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 60,
    active: false,
    cmcId: 17209,
    isDualFarm: true,
    newPool: 1642924800000,
    reward1: 10000,
    reward2: 140000,
    projectLink: "https://rxseedcoin.io/",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0xe70d287aad130e2cee520e75d12c6efa4f1a377d/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -55,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x9A78423668a0C95b775D73Ba186BB0a2DD6eEd01",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "FCF",
    tokenPrice: 0.0002335,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x4673f018cc6d401aad0402bdbf2abcbf43dd69f3",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    newPool: 1642250244000,
    isDualFarm: false,
    active: false,
    reward2: 25000000,
    projectLink: "https://frenchconnection.finance/",
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -54,
    risk: 5,
    lpSymbol: "FCF-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0xAA59d0C68F4d7895812fCDDB7e58F47b49639458",
    },
    lpAddresses: {
      97: "",
      56: "0xC58A80CF11d1CbbAE4fc1c2Fa9244F517676Ab3D",
    },
    tokenSymbol: "FCF",
    tokenPrice: 0.0002335,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x4673f018cc6d401aad0402bdbf2abcbf43dd69f3",
    },
    quoteTokenPrice: 440,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    newPool: 1642250244000,
    reward1: 10000,
    reward2: 40000000,
    projectLink: "https://frenchconnection.finance/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0x4673f018cc6d401AAD0402BdBf2abcBF43dd69F3",
  },
  {
    pid: -53,
    risk: 5,
    lpSymbol: "CRUSH-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0x2d007af36defe6ab6eb6d43a2c3a36563f3a8a72",
    },
    lpAddresses: {
      97: "",
      56: "0x323D9232bC8848baBE21C409CF1DF82081D19fD2",
    },
    tokenSymbol: "CRUSH",
    tokenPrice: 0.1,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0ef0626736c2d484a792508e99949736d0af807e",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 30,
    minStaking: 7,
    active: false,
    isBoosterPool: true,
    newPool: 1640177490000,
    isDualFarm: true,
    reward1: 10000,
    reward2: 70000,
    projectLink: "https://bitcrusharcade.io/",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x0Ef0626736c2d484A792508e99949736D0AF807e/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -52,
    risk: 5,
    lpSymbol: "CROX-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0x0b9c088d62c6adbaf126960002e11a899ba7598b",
    },
    lpAddresses: {
      97: "",
      56: "0xf41aCbA80328C076A207baDCbbd7bc331c0853f5",
    },
    tokenSymbol: "CRUSH",
    tokenPrice: 0.1,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0Ef0626736c2d484A792508e99949736D0AF807e",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 30,
    minStaking: 7,
    active: false,
    isDualFarm: true,
    isBoosterPool: true,
    newPool: 1640177490000,
    reward1: 30000,
    reward2: 18000,
    showBackground: true,
    projectLink: "https://croxswap.com",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x2c094F5A7D1146BB93850f629501eB749f6Ed491/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -51,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x8cd2ec2f49a3e6fc8bd3aa51012a68c8bf513264",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "BGLG",
    tokenPrice: 0.0126401,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xea01d8d9eacca9996db6bb3377c1fe64308e7328",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    isDualFarm: false,
    newPool: 1640177490000,
    active: false,
    reward2: 350000,
    projectLink: "https://bigleague.art/",
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -50,
    risk: 5,
    lpSymbol: "BGLG-BUSD LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x1e2494b00581a9bf9b649bbee7cf1087b8911d1f",
    },
    lpAddresses: {
      97: "",
      56: "0x859fA674937fd466C499A1e9a7BBd10946ec45b6",
    },
    tokenSymbol: "BGLG",
    tokenPrice: 0.012375,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xea01d8d9eacca9996db6bb3377c1fe64308e7328",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    newPool: 1640177490000,
    reward1: 12000,
    reward2: 800000,
    projectLink: "https://bigleague.art/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0xEa01D8D9EaCCa9996Db6Bb3377c1Fe64308e7328",
  },
  {
    pid: -49,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0xb093dbdc08fe7e0e24f64f8d4ad6be4eedcf3b92",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "BABY",
    tokenPrice: 1.77,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    isDualFarm: false,
    active: false,
    newPool: 1640177490000,
    reward2: 2000,
    projectLink: "https://babyswap.finance",
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -48,
    risk: 5,
    lpSymbol: "MILK-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0x8302431ec0c23110d5eb95f6d916992b4fafa50c",
    },
    lpAddresses: {
      97: "",
      56: "0xDB6c248902AD2722A5E01E9689824c28bd0A5f75",
    },
    tokenSymbol: "MILK",
    tokenPrice: 0.0111979,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xBf37f781473f3b50E82C668352984865eac9853f",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 30,
    active: false,
    isDualFarm: true,
    newPool: 1640177490000,
    reward1: 6000,
    reward2: 200000,
    projectLink: "https://thecryptoyou.io/home",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0xBf37f781473f3b50E82C668352984865eac9853f/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -47,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x9555636116a6d4c526c9b24572dd00dcbc78c5c0",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "FCF",
    tokenPrice: 0.0002335,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x4673f018cc6d401aad0402bdbf2abcbf43dd69f3",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    isDualFarm: false,
    active: false,
    reward2: 17000000,
    projectLink: "https://frenchconnection.finance/",
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -46,
    risk: 5,
    lpSymbol: "FCF-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x01c6e7e4d9419f38aee16c02c30ed72d465beaf4",
    },
    lpAddresses: {
      97: "",
      56: "0xC58A80CF11d1CbbAE4fc1c2Fa9244F517676Ab3D",
    },
    tokenSymbol: "FCF",
    tokenPrice: 0.0002335,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x4673f018cc6d401aad0402bdbf2abcbf43dd69f3",
    },
    quoteTokenPrice: 580,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 6000,
    reward2: 19000000,
    projectLink: "https://frenchconnection.finance/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0x4673f018cc6d401AAD0402BdBf2abcBF43dd69F3",
  },
  {
    pid: -45,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0xaa5765b33f44b1a4be918fa91a8cc1280bbadf46",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "WNOW",
    tokenPrice: 0.099658,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x56aa0237244c67b9a854b4efe8479cca0b105289",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    isDualFarm: false,
    active: false,
    reward2: 34000,
    projectLink: "https://walletnow.net/",
    depositLink: "https:exchange.croxswap.com",
  },
  {
    pid: -44,
    risk: 5,
    lpSymbol: "WNOW-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0xb2ce797119703bb2781187e1546f7d60cdca5377",
    },
    lpAddresses: {
      97: "",
      56: "0x268C6d2BD3F593d44F3e697CC5A02Ae6EcdA9a23",
    },
    tokenSymbol: "WNOW",
    tokenPrice: 0.099658,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x56aa0237244c67b9a854b4efe8479cca0b105289",
    },
    quoteTokenPrice: 580,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 6000,
    reward2: 56000,
    projectLink: "https://walletnow.net/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0x56AA0237244C67B9A854B4Efe8479cCa0B105289",
  },
  {
    pid: -43,
    risk: 5,
    lpSymbol: "CRUSH-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0x2ab18f5f72743446a7a3a942607398d7eeacecc7",
    },
    lpAddresses: {
      97: "",
      56: "0x323D9232bC8848baBE21C409CF1DF82081D19fD2",
    },
    tokenSymbol: "CRUSH",
    tokenPrice: 0.2812,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0ef0626736c2d484a792508e99949736d0af807e",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 14,
    minStaking: 1,
    active: false,
    isBoosterPool: true,
    isDualFarm: true,
    reward1: 5000,
    reward2: 36000,
    projectLink: "https://bitcrusharcade.io/",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x0Ef0626736c2d484A792508e99949736D0AF807e/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -42,
    risk: 5,
    lpSymbol: "CROX-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0x6fd7fc7999a464738376bb69b557d19d0da2e672",
    },
    lpAddresses: {
      97: "",
      56: "0xf41aCbA80328C076A207baDCbbd7bc331c0853f5",
    },
    tokenSymbol: "CRUSH",
    tokenPrice: 0.15,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0Ef0626736c2d484A792508e99949736D0AF807e",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 14,
    minStaking: 7,
    active: false,
    isDualFarm: true,
    isBoosterPool: true,
    reward1: 20000,
    reward2: 8000,
    showBackground: true,
    projectLink: "https://croxswap.com",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x2c094F5A7D1146BB93850f629501eB749f6Ed491/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -41,
    risk: 5,
    lpSymbol: "RASTA-CROX LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x287284971cca720f4ac7e2ae141857b8299e99b2",
    },
    lpAddresses: {
      97: "",
      56: "0x068025Cb947C59684D298F9ad8FE475E3944ae9f",
    },
    tokenSymbol: "RASTA",
    tokenPrice: 0.132543,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xe3e8cc42da487d1116d26687856e9fb684817c52",
    },
    quoteTokenPrice: 0.3,
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
    title: "",
    duration: 30,
    isDualFarm: true,
    active: false,
    reward1: 17000,
    reward2: 35000,
    showBackground: true,
    projectLink: "https://rasta.finance",
    depositLink:
      "https://exchange.croxswap.com/#/add/0xE3e8cC42DA487d1116D26687856e9FB684817c52/0x2c094F5A7D1146BB93850f629501eB749f6Ed491",
  },
  {
    pid: -40,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0xb9e9d0fd0f2881ba98818745cfa13b0054411467",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "RASTA",
    tokenPrice: 0.132543,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xe3e8cc42da487d1116d26687856e9fb684817c52",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    isDualFarm: false,
    active: false,
    reward2: 42000,
    showBackground: true,
    projectLink: "https://rasta.finance",
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -39,
    risk: 5,
    lpSymbol: "RASTA-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0xde3549a2d4135b896ab6665eff25fa90f8192caf",
    },
    lpAddresses: {
      97: "",
      56: "0x950b7377695e81235397da1b608c2087bc7002dc",
    },
    tokenSymbol: "RASTA",
    tokenPrice: 0.132543,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xe3e8cc42da487d1116d26687856e9fb684817c52",
    },
    quoteTokenPrice: 600,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 10000,
    reward2: 55000,
    showBackground: true,
    projectLink: "https://rasta.finance",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0xE3e8cC42DA487d1116D26687856e9FB684817c52",
  },
  {
    pid: -38,
    risk: 5,
    lpSymbol: "CRUSH-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0x2ef72492be796eee36b77cd465c7a3695abd7480",
    },
    lpAddresses: {
      97: "",
      56: "0x323D9232bC8848baBE21C409CF1DF82081D19fD2",
    },
    tokenSymbol: "CRUSH",
    tokenPrice: 0.2812,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0ef0626736c2d484a792508e99949736d0af807e",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 7,
    minStaking: 7,
    active: false,
    isBoosterPool: true,
    isDualFarm: true,
    reward1: 5000,
    reward2: 36000,
    projectLink: "https://bitcrusharcade.io/",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x0Ef0626736c2d484A792508e99949736D0AF807e/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -37,
    risk: 5,
    lpSymbol: "CROX-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0xbd094ae2b4b0afa2d09087c5c22485ea7de57b37",
    },
    lpAddresses: {
      97: "",
      56: "0xf41aCbA80328C076A207baDCbbd7bc331c0853f5",
    },
    tokenSymbol: "CRUSH",
    tokenPrice: 0.15,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0Ef0626736c2d484A792508e99949736D0AF807e",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 7,
    minStaking: 7,
    active: false,
    isDualFarm: true,
    isBoosterPool: true,
    reward1: 20000,
    reward2: 6000,
    showBackground: true,
    projectLink: "https://croxswap.com",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x2c094F5A7D1146BB93850f629501eB749f6Ed491/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -36,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x0cecf0997b94f553d80d413b026f48ba87024f8b",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "GAIN",
    tokenPrice: 0.171489,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xAAc073847e989C6D9d2f115A69E57a024415c684",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    isDualFarm: false,
    active: false,
    reward2: 5000,
    projectLink: "https://gainpool.io/",
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -35,
    risk: 5,
    lpSymbol: "GAIN-USDT LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x033dab6f5fa99e77bbd52341be328f1b26ff9463",
    },
    lpAddresses: {
      97: "",
      56: "0x4b558829a04B200F17a73d159BE5fCEa74d35Df2",
    },
    tokenSymbol: "GAIN",
    tokenPrice: 0.171489,
    quoteTokenPrice: 1,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xAAc073847e989C6D9d2f115A69E57a024415c684",
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 90,
    active: false,
    isDualFarm: true,
    reward1: 18000,
    reward2: 120000,
    projectLink: "https://gainpool.io/",
    depositLink:
      "https://exchange.croxswap.com/#/add/0x55d398326f99059fF775485246999027B3197955/0xAAc073847e989C6D9d2f115A69E57a024415c684",
  },
  {
    pid: -34,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x9b09dFac9a922313389cC519C9cc2b85b3b6c452",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "CNR+CRUSH",
    tokenPrice: 0.09,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0ef0626736c2d484a792508e99949736d0af807e",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    isDualFarm: false,
    active: false,
    minFirstDeposit: 3000,
    maxFirstDeposit: 12000,
    reward1: 23000,
    reward2: 23000,
    isWhalePool: true,
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -33,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0xf55a787bb66e9eff21fd63ca772a18a2e96001df",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "CNR+CRUSH",
    tokenPrice: 0.09,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0ef0626736c2d484a792508e99949736d0af807e",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    isDualFarm: false,
    maxFirstDeposit: 2500,
    active: false,
    reward1: 10000,
    reward2: 10000,
    isShrimpPool: true,
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -32,
    risk: 5,
    lpSymbol: "CNS-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0xf59db3b9a7cc26ac917cc617ff542b59f1132f34",
    },
    lpAddresses: {
      97: "",
      56: "0x50605A84752eA65d6FC8dB922bBd957a381920CC",
    },
    tokenDecimal: 8,
    tokenSymbol: "CNR",
    tokenPrice: 0.13,
    quoteTokenPrice: 630,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xdCbA3fbd7BBc28abD18A472D28358089467A8a74",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 15000,
    reward2: 65000,
    projectLink: "https://www.centric.com/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0xf6cb4ad242bab681effc5de40f7c8ff921a12d63",
  },
  {
    pid: -31,
    risk: 5,
    lpSymbol: "DMGK-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0xa0572c1b63b7918337dd8281dc4228463ea63330",
    },
    lpAddresses: {
      97: "",
      56: "0x0cd050602341dcc312005287c8fb480ec0da6abe",
    },
    tokenSymbol: "DMGK",
    tokenPrice: 0.0534331,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x6F89660fEf8B6a03a41E5F3d0306645BE2469eFE",
    },
    quoteTokenPrice: 650,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 90,
    minStaking: 30,
    active: false,
    isDualFarm: true,
    reward1: 25000,
    reward2: 570000,
    projectLink: "https://darkmagick.co/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0x6F89660fEf8B6a03a41E5F3d0306645BE2469eFE",
  },
  {
    pid: -30,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x43f15dd411dfea2051e4b8024cbffb43516c8d5b",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "DMGK",
    tokenPrice: 0.0534331,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x6F89660fEf8B6a03a41E5F3d0306645BE2469eFE",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 90,
    isDualFarm: false,
    active: false,
    reward2: 180000,
    cgProjectID: "darkmagick",
    projectLink: "https://darkmagick.co/",
    depositLink: "https://exchange.croxswap.com/",
  },
  {
    pid: -29,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x35c643417538f5a6dd8dd55e75986d76d621bd3e",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "GREM",
    tokenPrice: 0.0036,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x8c458EE3ebc5DaDFE5E0A3Daae47e3C2Aa62FF1c",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    isDualFarm: false,
    penaltyFee: 40,
    active: false,
    minFirstDeposit: 500,
    reward2: 10000000,
    cgProjectID: "gremlins-finance",
    projectLink: "https://gremlins.finance/",
    depositLink: "https://exchange.croxswap.com/#/swap",
  },
  {
    pid: -28,
    risk: 5,
    lpSymbol: "CLA-USDT LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x29108d47517adff3386ff756ebda8a413c4b2c78",
    },
    lpAddresses: {
      97: "",
      56: "0xcE6A9F4770F908dAc4E29090f397cE29547B569D",
    },
    tokenSymbol: "CLA",
    tokenPrice: 0.0033,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xF6663F46ECd7AE8eE8b6cC54681A42EbA0ef52b4",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 60,
    minStaking: 60,
    minFirstDeposit: 6000,
    active: false,
    isDualFarm: true,
    isWhalePool: true,
    reward1: 9000,
    reward2: 400000,
    projectLink: "https://www.candelacoin.com/",
    depositLink:
      "https://exchange.croxswap.com/#/add/0xF6663F46ECd7AE8eE8b6cC54681A42EbA0ef52b4/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -27,
    risk: 5,
    lpSymbol: "GAME1-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0xbd2e6cb67fef37371c46c801606c7115aabf9b91",
    },
    lpAddresses: {
      97: "",
      56: "0x29e63966792d692ef405d897bd716c30e4e54944",
    },
    tokenSymbol: "GAME1",
    tokenPrice: 0.01785158,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0e52d24c87a5ca4f37e3ee5e16ef5913fb0cceeb",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 30,
    active: false,
    isDualFarm: true,
    reward1: 5000,
    reward2: 100000,
    projectLink: "https://game1network.com/",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x0e52d24c87a5ca4f37e3ee5e16ef5913fb0cceeb/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -26,
    risk: 5,
    lpSymbol: "BABY-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0xdd979fbaff3ee0bb5de598a49a2e04e833306719",
    },
    lpAddresses: {
      97: "",
      56: "0xE730C7B7470447AD4886c763247012DfD233bAfF",
    },
    tokenSymbol: "BABY",
    tokenPrice: 0.5845,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 17500,
    reward2: 17500,
    projectLink: "https://home.babyswap.finance/",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -25,
    risk: 5,
    lpSymbol: "DFL-BNB LP",
    lpType: "JET LP",
    poolAddress: {
      97: "",
      56: "0x311350c56e623ba25d42de6892e70b949cff2611",
    },
    lpAddresses: {
      97: "",
      56: "0x362923F64cF94c4462De0d059Dd0997954Ce8E31",
    },
    tokenSymbol: "DFL",
    tokenPrice: 0.00539054,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xD675fF2B0ff139E14F86D87b7a6049ca7C66d76e",
    },
    quoteTokenPrice: 630,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    active: false,
    isDualFarm: true,
    reward1: 5000,
    reward2: 2000000,
    projectLink: "https://defily.io/",
    depositLink:
      "https://exchange.jetswap.finance/#/add/BNB/0xD675fF2B0ff139E14F86D87b7a6049ca7C66d76e",
  },

  {
    pid: -24,
    risk: 5,
    lpSymbol: "CROX-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0x351abaf5b3d7ee9a1ea814121f2ec76a35b05ac4",
    },
    lpAddresses: {
      97: "",
      56: "0xf41aCbA80328C076A207baDCbbd7bc331c0853f5",
    },
    tokenSymbol: "USDT",
    tokenPrice: 1,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x55d398326f99059fF775485246999027B3197955",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 60,
    minStaking: 60,
    active: false,
    isDualFarm: true,
    reward1: 160000,
    reward2: 12000,
    showBackground: true,
    projectLink: "https://croxswap.com",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x2c094F5A7D1146BB93850f629501eB749f6Ed491/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -23,
    risk: 5,
    lpSymbol: "POLAR-BUSD LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x0d0d3fddea48fdad1d69bec10143125e4e041598",
    },
    lpAddresses: {
      97: "",
      56: "0x2B7304ba278fa25caa8D44E26834eaba5965d919",
    },
    tokenSymbol: "POLAR",
    tokenPrice: 0.0106,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x70905594023Cb2f37F3103fDc70315ad1601D6EF",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    title: "",
    duration: 30,
    active: false,
    isDualFarm: true,
    reward1: 10000,
    reward2: 200000,
    projectLink: "https://polarisdefi.io/",
    depositLink:
      "https://exchange.croxswap.com/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x70905594023Cb2f37F3103fDc70315ad1601D6EF",
  },
  {
    pid: -22,
    risk: 5,
    lpSymbol: "CRUSH-USDT LP",
    lpType: "BABY LP",
    poolAddress: {
      97: "",
      56: "0x2fc8c3dc50d0f90d5f90a8aaf30b9f80b1bfb5eb",
    },
    lpAddresses: {
      97: "",
      56: "0x323D9232bC8848baBE21C409CF1DF82081D19fD2",
    },
    tokenSymbol: "CRUSH",
    tokenPrice: 0.2812,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0ef0626736c2d484a792508e99949736d0af807e",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    title: "",
    duration: 60,
    minStaking: 60,
    minFirstDeposit: 2800,
    active: false,
    isWhalePool: true,
    isDualFarm: true,
    reward1: 12000,
    reward2: 100000,
    projectLink: "https://bitcrusharcade.io/",
    depositLink:
      "https://exchange.babyswap.finance/#/add/0x0Ef0626736c2d484A792508e99949736D0AF807e/0x55d398326f99059fF775485246999027B3197955",
  },
  {
    pid: -21,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0xda66d1c7cbf16478321ab3b5020dd02ceb1925f0",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "GOLDNUGGET",
    tokenPrice: 0.27,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xe0b58022487131ec9913c1f3acfd8f74fc6a6c7e",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    isDualFarm: false,
    active: false,
    reward2: 20000,
    projectLink: "https://block-mine.io/",
    depositLink:
      "https://exchange.croxswap.com/#/swap?outputCurrency=0x2c094f5a7d1146bb93850f629501eb749f6ed491",
  },
  {
    pid: -20,
    risk: 5,
    lpSymbol: "GOLDNUGGET-BNB LP",
    lpType: "APE LP",
    poolAddress: {
      97: "",
      56: "0x68c03ca9ccd68e049f7786fb6cb0d865528b6070",
    },
    lpAddresses: {
      97: "",
      56: "0x93ebebff79C504e1F0B6946A6F2fe693E811470A",
    },
    tokenSymbol: "NUGGET",
    tokenPrice: 0.54,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xe0b58022487131ec9913c1f3acfd8f74fc6a6c7e",
    },
    quoteTokenPrice: 630,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    penaltyFee: 5,
    reward1: 8000,
    reward2: 40000,
    projectLink: "https://block-mine.io/",
    depositLink:
      "https://app.apeswap.finance/add/ETH/0xE0B58022487131eC9913C1F3AcFD8F74FC6A6C7E",
  },
  {
    pid: -19,
    risk: 5,
    lpSymbol: "NUX-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x4332d31aa04d19c98f4488066863b097a36e4478",
    },
    lpAddresses: {
      97: "",
      56: "0x9099502f8f2db7fbd442d0d1bbd63310eff908ea",
    },
    tokenSymbol: "NUX",
    tokenPrice: 0.2672,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x6D8734002fBffE1c86495e32c95f732fC77F6F2A",
    },
    quoteTokenPrice: 630,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 10000,
    reward2: 60000,
    projectLink: "https://peanut.trade/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0x6D8734002fBffE1c86495e32c95f732fC77F6F2A",
  },
  {
    pid: -18,
    risk: 5,
    lpSymbol: "PINO",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x5b254beb8325956977f37a66a4a59d88d5350128",
    },
    lpAddresses: {
      97: "",
      56: "0x6b681fc5bba4a85f50e6f0a314a4d5452a9b3384",
    },
    tokenSymbol: "PINO",
    tokenPrice: 0.6363,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x6b681fc5bba4a85f50e6f0a314a4d5452a9b3384",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    isDualFarm: false,
    isHintVisible: false,
    active: false,
    reward2: 2000,
    projectLink: "https://www.pinocchu.com/",
    depositLink:
      "https://exchange.croxswap.com/#/swap?outputCurrency=0x6B681fc5BbA4a85F50E6f0A314A4D5452a9B3384",
  },
  {
    pid: -17,
    risk: 5,
    lpSymbol: "PINO-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x0849b92fdb7ed9af55c00cb8b9147065143946f3",
    },
    lpAddresses: {
      97: "",
      56: "0x98c5ACDc6Fe2784C5bE122F4398254A479Ae6A29",
    },
    tokenSymbol: "PINO",
    tokenPrice: 0.6363,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x6b681fc5bba4a85f50e6f0a314a4d5452a9b3384",
    },
    quoteTokenPrice: 630,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 6000,
    reward2: 3000,
    projectLink: "https://www.pinocchu.com/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0x6B681fc5BbA4a85F50E6f0A314A4D5452a9B3384",
  },
  {
    pid: -16,
    risk: 5,
    lpSymbol: "CROX-BNB LP",
    lpType: "BISWAP LP ",
    poolAddress: {
      97: "",
      56: "0x7ed56703ff95e17bfce01015453e97582392a463",
    },
    lpAddresses: {
      97: "",
      56: "0xFd9A43966ECf3E78714133bD86849154a2726FD7",
    },
    tokenSymbol: "CROX",
    tokenIcon: "crox-bnb-biswap",
    tokenPrice: 0.27,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    quoteTokenPrice: 630,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    minStaking: 60,
    isDualFarm: false,
    active: false,
    projectLink: "https://croxswap.com",
    reward2: 260000,
    depositLink:
      "https://exchange.biswap.org/#/add/ETH/0x2c094F5A7D1146BB93850f629501eB749f6Ed491",
    isLPToken: true,
    showBackground: true,
  },
  {
    pid: -15,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0xe3de39b320d3b2f473beb8e9ef79f98769662ef1",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "CLA",
    tokenPrice: 0.0033,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xF6663F46ECd7AE8eE8b6cC54681A42EbA0ef52b4",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    isDualFarm: false,
    active: false,
    reward2: 100000,
    projectLink: "https://www.candelacoin.com/",
    depositLink:
      "https://exchange.croxswap.com/#/swap?outputCurrency=0x2c094f5a7d1146bb93850f629501eb749f6ed491",
  },
  {
    pid: -14,
    risk: 5,
    lpSymbol: "CLA-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x0bc9f266b20bbe857a22733bb5b725b789803e22",
    },
    lpAddresses: {
      97: "",
      56: "0xb0d5b67aa55c29064a386ee53231d83bbf6d96dc",
    },
    tokenSymbol: "CLA",
    tokenPrice: 0.0033,
    quoteTokenPrice: 630,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xF6663F46ECd7AE8eE8b6cC54681A42EbA0ef52b4",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 20000,
    reward2: 480000,
    projectLink: "https://www.candelacoin.com/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0xF6663F46ECd7AE8eE8b6cC54681A42EbA0ef52b4",
  },
  {
    pid: -13,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x946ee41bacd6e03b5fed29187e9b763c24eecee5",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenDecimal: 8,
    tokenSymbol: "CNR",
    tokenPrice: 0.13,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xdCbA3fbd7BBc28abD18A472D28358089467A8a74",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    isDualFarm: false,
    active: false,
    reward2: 216000,
    projectLink: "https://www.centric.com/",
    depositLink:
      "https://exchange.croxswap.com/#/swap?outputCurrency=0x2c094f5a7d1146bb93850f629501eb749f6ed491",
  },
  {
    pid: -12,
    risk: 5,
    lpSymbol: "CNS-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0xcda5e7bfcb0970a1f56810d8a04f9917ab70038a",
    },
    lpAddresses: {
      97: "",
      56: "0x50605A84752eA65d6FC8dB922bBd957a381920CC",
    },
    tokenDecimal: 8,
    tokenSymbol: "CNS",
    tokenPrice: 0.13,
    quoteTokenPrice: 630,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xdCbA3fbd7BBc28abD18A472D28358089467A8a74",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 30000,
    reward2: 432000,
    projectLink: "https://www.centric.com/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0xf6cb4ad242bab681effc5de40f7c8ff921a12d63",
  },
  {
    pid: -11,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0xa5e505d73779c2357e90c25ee581256cae3210fa",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "CRUSH",
    tokenPrice: 0.2665,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0ef0626736c2d484a792508e99949736d0af807e",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    isDualFarm: false,
    active: false,
    reward2: 33333,
    projectLink: "https://bitcrusharcade.io/",
    depositLink:
      "https://exchange.croxswap.com/#/swap?outputCurrency=0x2c094f5a7d1146bb93850f629501eb749f6ed491",
  },
  {
    pid: -10,
    risk: 5,
    lpSymbol: "CRUSH-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0xe8f108fd67423a24fe7b40c4a208ab8c41d86915",
    },
    lpAddresses: {
      97: "",
      56: "0x5bD6F198Ce9011550c31fC7FfC33305A4fbEa674",
    },
    tokenSymbol: "CRUSH",
    tokenPrice: 0.340979,
    quoteTokenPrice: 630,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x0ef0626736c2d484a792508e99949736d0af807e",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 15000,
    reward2: 66600,
    projectLink: "https://bitcrusharcade.io/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0x0ef0626736c2d484a792508e99949736d0af807e",
  },
  {
    pid: -9,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0xb9239eecf244927840e2ce315fd14a16d278c1c4",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenDecimal: 8,
    tokenSymbol: "CNS",
    tokenPrice: 0.0008416,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xf6cb4ad242bab681effc5de40f7c8ff921a12d63",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    isDualFarm: false,
    active: false,
    projectLink: "https://www.centric.com/",
    depositLink:
      "https://exchange.croxswap.com/#/swap?outputCurrency=0x2c094f5a7d1146bb93850f629501eb749f6ed491",
  },
  {
    pid: -8,
    risk: 5,
    lpSymbol: "CNS-BNB LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0xac43af73e33589d22de23cc56eb081069cc5dfca",
    },
    lpAddresses: {
      97: "",
      56: "0x50605A84752eA65d6FC8dB922bBd957a381920CC",
    },
    tokenDecimal: 8,
    tokenSymbol: "CNS",
    tokenPrice: 0.0008416,
    quoteTokenPrice: 630,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xf6cb4ad242bab681effc5de40f7c8ff921a12d63",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    projectLink: "https://www.centric.com/",
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0xf6cb4ad242bab681effc5de40f7c8ff921a12d63",
  },
  {
    pid: -7,
    risk: 5,
    lpSymbol: "CROX",
    lpType: "No Fees",
    poolAddress: {
      97: "",
      56: "0x168e09d8ad9478ec51df3b054d43652a3254bd1f",
    },
    lpAddresses: {
      97: "",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    tokenSymbol: "RASTA",
    tokenPrice: 0.16,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xe3e8cc42da487d1116d26687856e9fb684817c52",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    isDualFarm: false,
    active: false,
    reward2: 5000,
    projectLink: "https://rasta.finance",
    depositLink:
      "https://exchange.croxswap.com/#/swap?outputCurrency=0x2c094f5a7d1146bb93850f629501eb749f6ed491",
  },
  {
    pid: -3,
    risk: 5,
    lpSymbol: "CROX-DAI LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x999c1fdea305ae7d5eb6709149c3f3a896667336",
    },
    lpAddresses: {
      97: "",
      56: "0xAfECBd00A925Ca4B5443587790F55a01f2F55332",
    },
    tokenSymbol: "DAI",
    tokenPrice: 1,
    quoteTokenPrice: 1,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
    title: "",
    duration: 60,
    isDualFarm: true,
    active: false,
    reward1: 20000,
    reward2: 10000,
    projectLink: "https://rasta.finance",
    depositLink:
      "https://exchange.croxswap.com/#/add/0x2c094F5A7D1146BB93850f629501eB749f6Ed491/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
  },
  {
    pid: -4,
    risk: 5,
    lpSymbol: "RASTA-WBNB LP",
    lpType: "RASTA LP",
    poolAddress: {
      97: "",
      56: "0x6604863ea549d65a176205345275202b8cf97ce6",
    },
    lpAddresses: {
      97: "",
      56: "0xE84413e4D2CE15Dd89141C88e5f8E46eEb0DE10C",
    },
    tokenSymbol: "RASTA",
    tokenPrice: 0.12,
    quoteTokenPrice: 630,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xe3e8cc42da487d1116d26687856e9fb684817c52",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    reward1: 5000,
    reward2: 5000,
    projectLink: "https://rasta.finance",
    depositLink:
      "https://exchange.rasta.finance/#/add/ETH/0xE3e8cC42DA487d1116D26687856e9FB684817c52",
  },
  {
    pid: -5,
    risk: 5,
    lpSymbol: "GNT-BUSD LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x4b113451dcbec996c665454c85700c02d6a29e5e",
    },
    lpAddresses: {
      97: "",
      56: "0xa0b60F9b98ED62c2Be5e92E0666f1fc9B59Af7bD",
    },
    tokenSymbol: "GNT",
    tokenPrice: 0.000003968,
    quoteTokenPrice: 1,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xF750A26EB0aCf95556e8529E72eD530f3b60f348",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    projectLink: "https://www.greentrusttoken.com/",
    depositLink:
      "https://exchange.croxswap.com/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xF750A26EB0aCf95556e8529E72eD530f3b60f348",
  },
  {
    pid: -1,
    risk: 5,
    lpSymbol: "CROX-DAI LP",
    lpType: "CAKE LP",
    poolAddress: {
      97: "",
      56: "0x543ad651396a68010a1d40c7dbd6c1af12268eca",
    },
    lpAddresses: {
      97: "",
      56: "0xAfECBd00A925Ca4B5443587790F55a01f2F55332",
    },
    tokenSymbol: "DAI",
    tokenPrice: 1,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    projectLink: "https://croxswap.com",
    depositLink:
      "https://exchange.croxswap.com/#/add/0x2c094F5A7D1146BB93850f629501eB749f6Ed491/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
  },
  {
    pid: -2,
    risk: 5,
    lpSymbol: "RASTA-WBNB LP",
    lpType: "RASTA LP",
    poolAddress: {
      97: "",
      56: "0xc38f31e73d3c5386f062d083ed32daf4d3f6df8b",
    },
    lpAddresses: {
      97: "",
      56: "0xE84413e4D2CE15Dd89141C88e5f8E46eEb0DE10C",
    },
    tokenSymbol: "RASTA",
    tokenPrice: 0.13,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0xe3e8cc42da487d1116d26687856e9fb684817c52",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 60,
    active: false,
    isDualFarm: true,
    projectLink: "https://croxswap.com",

    depositLink:
      "https://exchange.rasta.finance/#/add/ETH/0xE3e8cC42DA487d1116D26687856e9FB684817c52",
  },
  {
    pid: 35,
    risk: 5,
    lpSymbol: "CROX-BNB LP",
    lpType: "CAKE LP ",
    poolAddress: {
      97: "",
      56: "0xed2efe8294cd2ac9e7a2dec692c25f218f9cc214",
    },
    lpAddresses: {
      97: "",
      56: "0xE38e899cc99ddeA9737e06f0A22046d0CA904D70",
    },
    tokenSymbol: "CROX",
    tokenIcon: 'crox-bnb',
    tokenPrice: 0.0115,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    quoteTokenPrice: 296,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title: "",
    duration: 30,
    minStaking: 30,
    isDualFarm: false,
    active: true,
    projectLink: "https://croxswap.com",
    reward2: 10000,
    newPool: 1653158677000,
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0x2c094F5A7D1146BB93850f629501eB749f6Ed491",
    isLPToken: true,
    showBackground: true,
  },
  {
    pid: 36,
    risk: 5,
    lpSymbol: "CROX-BUSD LP",
    lpType: "CAKE LP ",
    poolAddress: {
      97: "",
      56: "0xdb8950ec064b85803ab07400aad9e24c67495167",
    },
    lpAddresses: {
      97: "",
      56: "0xe1d5c90e94d73625628b2E6B7AEAbF5f1c504872",
    },
    tokenSymbol: "CROX",
    tokenIcon: 'crox-busd',
    tokenPrice: 0.0115,
    tokenAddresses: {
      97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
      56: "0x2c094f5a7d1146bb93850f629501eb749f6ed491",
    },
    quoteTokenPrice: 1,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    title: "",
    duration: 30,
    minStaking: 30,
    isDualFarm: false,
    active: true,
    newPool: 1653158677000,
    projectLink: "https://croxswap.com",
    reward2: 10000,
    depositLink:
      "https://exchange.croxswap.com/#/add/ETH/0x2c094F5A7D1146BB93850f629501eB749f6Ed491",
    isLPToken: true,
    showBackground: true,
  },
  // {
  //   pid: -3,
  //   risk: 5,
  //   lpSymbol: "CROX-CAKE LP",
  //   lpType: "CAKE LP",
  //   poolAddress: {
  //     97: "",
  //     56: "0x7a71abf96573126c18d0393fc8b716813fc92fb7",
  //   },
  //   lpAddresses: {
  //     97: "",
  //     56: "0x2B64133686cC7297df941E972a90cEB2c957d7cb",
  //   },
  //   tokenSymbol: "CAKE ",
  //   tokenPrice: 22.1,
  //   tokenAddresses: {
  //     97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
  //     56: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
  //   },
  //   quoteTokenSymbol: QuoteToken.CAKE,
  //   quoteTokenAdresses: contracts.cake,
  //   title: "",
  //   duration: 60,
  //   isDualFarm: true,
  // },
  // {
  //   pid: -1,
  //   risk: 5,
  //   lpSymbol: "RASTA-WBNB LP",
  //   poolAddress: {
  //     97: "",
  //     56: "0x2B64133686cC7297df941E972a90cEB2c957d7cb",
  //   },
  //   lpAddresses: {
  //     97: "",
  //     56: "0xE84413e4D2CE15Dd89141C88e5f8E46eEb0DE10C",
  //   },
  //   tokenSymbol: "RASTA",
  //   tokenAddresses: {
  //     97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
  //     56: "0xe3e8cc42da487d1116d26687856e9fb684817c52",
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   title: "",
  // },
  // {
  //   pid: -1,
  //   risk: 5,
  //   lpSymbol: "CROX-CAKE LP",
  //   poolAddress: {
  //     97: "",
  //     56: "0x2B64133686cC7297df941E972a90cEB2c957d7cb",
  //   },
  //   lpAddresses: {
  //     97: "",
  //     56: "0x2B64133686cC7297df941E972a90cEB2c957d7cb",
  //   },
  //   tokenSymbol: "CAKE",
  //   tokenAddresses: {
  //     97: "0x7F511033cFDa8dF0189f9c9BEaD981ae0496901C",
  //     56: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
  //   },
  //   quoteTokenSymbol: QuoteToken.CAKE,
  //   quoteTokenAdresses: contracts.cake,
  //   title: "",
  // },
];
export default farms;
